<template>
  <div>
    <loading-overlay :active="loading" :is-full-page="true" loader="bars"/>
    <CModalExtended
      :title="titleModal"
      color="dark"
      :show.sync="modalActive"
      :closeOnBackdrop="false"
      @update:show="closeModal()"
      size="lg"
      class="modal-extended"
    >
      <CRow class="justify-content-center">
        <CCol sm="12" lg="8">
          <CSelect
            :label="$t('label.group')"
            :placeholder="$t('label.select')"
            :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
            v-model="$v.GroupId.$model"
            :options="GroupOptions"
            addLabelClasses="required text-right"
            :is-valid="hasError($v.GroupId)"
            :invalid-feedback="errorMessage($v.GroupId)"
            @change="GroupFilter"
          />
          <CSelect
            :label="$t('label.cargoType')"
            :placeholder="$t('label.select')"
            :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
            v-model="$v.CargoTypeId.$model"
            :options="CargoTypeOptions"
            addLabelClasses="required text-right"
            :is-valid="hasError($v.CargoTypeId)"
            :invalid-feedback="errorMessage($v.CargoTypeId)"
            @change="CargoTypeFilter"
          />
        </CCol>
        <CCol sm="12">
          <dataTableExtended
            class="align-center-row-datatable"
            :fields="fields"
            :items="computedEventList"
            :items-per-page="5"
            column-filter
            pagination
            sorter
            :items-per-page-select="tableText.itemsPerPageText"
            :tableFilter="tableText.tableFilterText"
            :noItemsView="tableText.noItemsViewText"
          >
            <template #Checked-filter>
              <CInputCheckbox
                :label="$t('label.all')"
                custom
                :checked="CheckedAll"
                class="checkbook-filter"
                @update:checked="SelectAll($event)"
              />
            </template>
            <template #Checked="{ item }">
              <td class="text-center">
                <CInputCheckbox
                  custom
                  :checked="item.Checked"
                  class="checkbook-filter"
                  @update:checked="addRemoveEvent(item)"
                />
              </td>
            </template>
          </dataTableExtended>
          <p v-if="$v.CheckedEvents.$error" class="mt-2 text-danger text-center">
            * {{  `${$t('label.must')}${$t('label.toSelect')}${$t('label.atLeast')}`}} 1
          </p>
        </CCol>
      </CRow>

      <template #footer>
        <CButton
          square
          color="add"
          class="d-flex align-items-center"
          @click.stop="Submit()"
          :disabled="isSubmit"
        >
          <CIcon name="checkAlt"/>{{$t('button.accept')}}
        </CButton>
        <CButton
          square
          color="wipe"
          class="d-flex align-items-center"
          @click="closeModal()"
        >
          <CIcon name="x" /><span class="ml-1"
            >{{$t('button.cancel')}}</span
          >
        </CButton>
      </template>
    </CModalExtended>
  </div>
</template>
<script>
import ModalMixin from '@/_mixins/modal';
import GeneralMixin from '@/_mixins/general';
import CargoTypeValidations from '@/_validations/event/cargoTypeValidations';

function data() {
  return {
    isSubmit: false,
    modalActive: false,
    loading: false,
    GroupId: '',
    GroupList: [],
    CargoTypeId: '',
    CargoTypeList: [],
    EventList: [],
    CheckedEvents: [],
    OriginCheckedEvents: [],
    CheckedAll: false,
  };
}

//methods
function ConsultServices(GpoCargo, TpCargo, EventCargo) {
  this.loading = true;
  this.EventList = [];
  this.CheckedEvents = [];
  let requests = [];
  if (GpoCargo) {
    requests[0] = this.$http.ejecutar("GET", "GpoCargoEvent-list");
  }
  if (TpCargo) {
    requests[1] = this.$http.ejecutar("GET", "TpCargo-list-by-GpoCargoId", {GpoCargoId : this.GroupId});
  }
  if (EventCargo) {
    requests[2] = this.$http.ejecutar("GET", "EventCargoAll-list", {GpoCargoId : this.GroupId, TpCargoId: this.CargoTypeId});
  }
  Promise.all(requests)
    .then((responses) => {
      if (GpoCargo) {
        let GpoCargoEvent = responses[0].data.data;
        this.GroupList  = GpoCargoEvent&&GpoCargoEvent.length!=0 ? GpoCargoEvent : [];
      }
      if (TpCargo) {
        let TpCargoList = responses[1].data.data;
        this.CargoTypeList = TpCargoList&&TpCargoList.length!=0 ? TpCargoList : [];
      }
      if (EventCargo) {
        let EventCargoAll = responses[2].data.data;
        this.EventList = EventCargoAll&&EventCargoAll.length!=0 ? EventCargoAll : [];
        this.EventList.map(item =>{
          if (item.EventCargoId) {
            this.CheckedEvents.push(item);
          }
        });
        this.OriginCheckedEvents = this.CheckedEvents;
      }
      this.loading = false;
    }).catch((err) => {
      this.loading = false;
      this.$notify({
        group: "container",
        title: "¡Error!",
        text: err,
        type: "error",
      });
    })
}

function Submit() {
  try {
    this.isSubmit = true;
		this.loading = true;
    this.$v.$touch();
    if (this.$v.$error) {
      throw this.$t('label.errorsPleaseCheck');
    }
    let TotalCheckedEvents = [];
    let EventJson = [];
    TotalCheckedEvents = [...this.OriginCheckedEvents, ...this.CheckedEvents];
    TotalCheckedEvents.map(item =>{
      if (!EventJson.some(EventJson => EventJson.EventId == item.EventId)) {
        EventJson.push(item);
      }
    })
    let EventCargoJson = {
      TpCargoId: this.CargoTypeId,
      EventJson:EventJson.map(item =>{
        let ValidateCurrentEvent = this.CheckedEvents.some(CheckedEvents =>
          CheckedEvents.EventId == item.EventId
        );
        return {
          EventId: item.EventId,
          Status: ValidateCurrentEvent ? 1 : 0,
        }
      })
    }
    let metodo = 'POST';
    let ruta = 'EventCargo-insert';
    this.$http
      .ejecutar( metodo, ruta, EventCargoJson, { root: 'EventCargoJson' })
      .then((response) => {
				this.closeModal();
				this.$emit('List-Update');
        this.notifySuccess({text: response.data.data[0].Response})
        this.loading = false;
        this.isSubmit = false;
      }).catch((e) => {
        this.isSubmit = false;
        this.loading = false;
        this.notifyError({text: e});
      });
  } catch (e) {
    this.isSubmit = false;
    this.loading = false;
    this.notifyError({text: e});
  }
}

function GroupFilter(event) {
  this.GroupId = event.target.value;
  this.ConsultServices(false, this.GroupId ? true : false);
}

function CargoTypeFilter(event) {
  this.CargoTypeId = event.target.value;
  this.ConsultServices(false, false, this.CargoTypeId ? true : false);
}

function SelectAll(event) {
  if (event) {
    this.CheckedEvents = this.EventList;
  } else {
    this.CheckedEvents = [];
  }
  this.CheckedAll = event;
}

function addRemoveEvent(item) {
  let Event = this.CheckedEvents.some(CheckedEvent => CheckedEvent.EventId == item.EventId)
  if (!Event) {
    //ADD
    this.CheckedEvents.push(item)
  }else{
    //DELETE
    this.CheckedEvents = this.CheckedEvents.filter((CheckedEvent) => CheckedEvent.EventId !== item.EventId);
  }
}

function getdata(val) {
  this.GroupId = val.GpoCargoId;
  this.CargoTypeId = val.TpCargoId;
  this.ConsultServices(true, true, true);
  this.$v.$touch();
}

function closeModal() {
  this.GroupId = '';
  this.CargoTypeId = '';
  this.GroupList = [];
  this.CargoTypeList = [];
  this.EventList = [];
  this.CheckedEvents = [];
  this.OriginCheckedEvents = [];
  this.CheckedAll = false;
  this.$v.$reset();
  this.$emit('Close-Modal');
}

//computed
function GroupOptions() {
  if(this.GroupList.length === 0){
    return [{
      value: '', 
      label: this.$t('label.select'),
    }];
  }else{
    let chart = [{
      value: '', 
      label: this.$t('label.select'),
    }]
    this.GroupList.map(function(e){
      chart.push({
        value: e.GpoCargoId, 
        label: e.GpoCargoName,
      })    
    })
    return chart;
  }
}

function CargoTypeOptions() {
  let _lang = this.$i18n.locale;
  if(this.CargoTypeList.length === 0){
    return [{
      value: '', 
      label: this.$t('label.select'),
    }];
  }else{
    let chart = [{
      value: '', 
      label: this.$t('label.select'),
    }]
    this.CargoTypeList.map(function(e){
      chart.push({
        value: e.TpCargoId, 
        label: _lang=='en' ? e.TpCargoNameEn : e.TpCargoNameEs,
      })    
    })
    return chart;
  }
}

function titleModal() {
  if (this.isEdit) {
    return `${this.$t('label.edit')} ${this.$t('label.AssociateCargoType')}`;
  } else {
    return `${this.$t('label.AssociateCargoType')}`;
  }
}

function fields(){
  return [
    { label: '#', key: 'Nro', _classes: 'text-center', _style: 'width:5%; text-align:center', filter: false},
    { label: this.$t('label.classification'), key: 'EventGpoName', _classes: 'text-center', _style: 'width:30%;',},
    { label: this.$t('label.Event'), key: 'EventName', _classes: 'text-center', _style: 'width:60%;',},
    { label: '', key: 'Checked', _classes: 'text-center', _style: 'width:5%;', filter: false},
  ];
}

function computedEventList() {
    let _lang = this.$i18n.locale;
    return this.EventList.map((item) => {
      let Checked = this.CheckedEvents.some(CheckedEvent => CheckedEvent.EventId == item.EventId)
      return {
        ...item,
        EventGpoName: _lang=='en' ? item.EventGpoNameEn : item.EventGpoNameEs,
        EventName: _lang=='en' ? item.EventNameEn : item.EventNameEs,
        Checked: Checked,
      };
    });
  }

export default {
  name: 'modal-cargo-type',
  props: { modal: Boolean, isEdit: Boolean, CargoTypeItem: Object },
  data,
  mixins: [
    ModalMixin,
    GeneralMixin,
  ],
  validations: CargoTypeValidations,
  methods: {
    SelectAll,
    addRemoveEvent,
    closeModal,
    getdata,
    Submit,
    ConsultServices,
    GroupFilter,
    CargoTypeFilter,
  },
  computed: {
    titleModal,
    CargoTypeOptions,
    GroupOptions,
    fields,
    computedEventList,
  },
  watch: {
    modal: function(val){
      this.modalActive = val;
      if (val) {
        if (this.isEdit) {
          this.getdata(this.CargoTypeItem);
        } else {
          this.ConsultServices(true);
        }
      }
    }
  },
 
};
</script>